<template>
	<v-card elevation="5" style="min-height: 455px">
		<v-card-text
			class="text-sm-center text-h2 pt-5 pb-5"
			v-bind:class="isKPIExceeded(kpiId, kpiValue) ? 'red--text' : 'green--text'"
			v-if="kpiValue"
			v-bind:title="isKPIExceeded(kpiId, kpiValue) ? titleValueExceeded : titleValueNoExceeded"
		>
			{{ kpiValue }}
		</v-card-text>
		<v-card-text class="text-sm-center text-h2 pt-5 pb-5" v-else> - </v-card-text>
		<v-divider></v-divider>
		<v-card-title>{{ kpiLabel }}</v-card-title>
		<v-timeline align-top dense class="mb-4" style="min-height: 288px">
			<div v-for="event in eventsList" :key="event.label">
				<v-timeline-item small :color="event.value != null ? 'blue darken-2' : 'grey'">
					<div>
						<div class="font-weight-normal">
							<strong>{{ event.label }}</strong>
						</div>
						<div>{{ formatDate(event.value) ? formatDate(event.value) : $t('form.stopperformance.nodata') }}</div>
					</div>
				</v-timeline-item>
			</div>
		</v-timeline>
		<!--<v-divider></v-divider>
		<v-card-title>{{ $t('form.stopperformance.comparative') }} {{ getIdleTime(kpiId) }}</v-card-title>
		<stop-performance-barchar
			:kpiId="kpiId"
			:dailyMean="dailyMean"
			:weeklyMean="weeklyMean"
			:kpi="getIdleTime(kpiId)"
			:portId="portId"
			:locationId="locationId"
		></stop-performance-barchar>-->
	</v-card>
</template>

<script>
import StopPerformanceMixin from '@/mixins/StopPerformanceMixin.js';
import StopPerformanceBarchar from './StopPerformanceBarchar.vue';

export default {
	components: { StopPerformanceBarchar },
	mixins: [StopPerformanceMixin],
	props: {
		kpiId: {
			type: String,
			required: true
		},
		kpiLabel: {
			type: String,
			required: true
		},
		kpiValue: {
			type: String | null,
			required: true
		},
		titleValueExceeded: {
			type: String,
			required: true
		},
		titleValueNoExceeded: {
			type: String,
			required: true
		},
		dailyMean: {
			type: String | null,
			required: true
		},
		weeklyMean: {
			type: String | null,
			required: true
		},
		eventsList: {
			type: Array | null,
			required: true
		},
		portId: {
			type: Number,
			required: true
		},
		locationId: {
			type: Number,
			required: true
		}
	},
	methods: {
		isKPIExceeded(label, value) {
			if (value && this.exceedTargetIdleTime(value, label, this.portId, this.locationId)) {
				return true;
			}
			return false;
		},
		formatDate(dateInput) {
			if (dateInput) {
				return this.$dateTimeUtils.getLocalFormattedDate(dateInput, `${this.$store.getters.dateFormat} ${this.$store.getters.timeFormat}`);
			}
			return null;
		},
		getIdleTime(label) {
			let targetIdleTime = this.getTargetIdleTime(label, this.portId, this.locationId);

			if (!targetIdleTime) {
				return this.$t('form.stopperformance.noestablecido');
			}

			let days = targetIdleTime / 24 / 60;
			if (days < 1) {
				days = '00';
			} else if (days < 10) {
				days = '0' + Math.round(days);
			} else {
				days = '' + Math.round(days);
			}

			let hours = (targetIdleTime / 60) % 24;

			if (hours < 1) {
				hours = '00';
			} else if (hours < 10) {
				hours = '0' + Math.round(hours);
			} else {
				hours = '' + Math.round(hours);
			}

			let minutes = targetIdleTime % 60;
			if (minutes < 1) {
				minutes = '00';
			} else if (minutes < 10) {
				minutes = '0' + Math.round(minutes);
			} else {
				minutes = '' + Math.round(minutes);
			}

			return days + ' ' + hours + ':' + minutes + ':00';
		}
	}
};
</script>
