<template>
	<div class="overflow-y-auto" :style="{ 'max-height': maxHeight }" v-if="modelLoaded">
		<v-container fluid>
			<v-row dense>
				<v-col cols="12">
					<h2>{{ model.vesselname }} - {{ model.berthname }}</h2>
				</v-col>
			</v-row>
		</v-container>
		<v-container fluid>
			<pui-field-set :id="'stopperformance-form-fieldset-entidadportuaria'" :title="$t('grid.stopperformance.entidadportuaria')" class="pb-30">
				<v-row class="mt-1 mb-3 ml-1 mr-1">
					<v-col xs="12" sm="12" md="6" lg="3">
						<stop-performance-form-card
							:kpiId="'llegadaatraque'"
							:kpiLabel="$t('grid.stopperformance.llegadaatraque')"
							:kpiValue="model.llegadaatraque"
							:titleValueExceeded="$t('grid.stopperformance.llegadaatraque_desc_sup')"
							:titleValueNoExceeded="$t('grid.stopperformance.llegadaatraque_desc_no_sup')"
							:dailyMean="model.arrivalberthingdailymean"
							:weeklyMean="model.arrivalberthingweeklymean"
							:eventsList="arrivalberthingEventList"
							:portId="model.portid"
							:locationId="model.locationid"
						></stop-performance-form-card>
					</v-col>
					<v-col xs="12" sm="12" md="6" lg="3">
						<stop-performance-form-card
							:kpiId="'atraqueiniciooperaciones'"
							:kpiLabel="$t('grid.stopperformance.atraqueiniciooperaciones')"
							:kpiValue="model.atraqueiniciooperaciones"
							:titleValueExceeded="$t('grid.stopperformance.atraqueiniciooperaciones_desc_sup')"
							:titleValueNoExceeded="$t('grid.stopperformance.atraqueiniciooperaciones_desc_no_sup')"
							:dailyMean="model.berthingfirstcargoopdailymean"
							:weeklyMean="model.berthingfirstcargoopweeklymean"
							:eventsList="berthingfirstcargoEventList"
							:portId="model.portid"
							:locationId="model.locationid"
						></stop-performance-form-card>
					</v-col>
					<v-col xs="12" sm="12" md="6" lg="3">
						<stop-performance-form-card
							:kpiId="'finoperacionesdesatraque'"
							:kpiLabel="$t('grid.stopperformance.finoperacionesdesatraque')"
							:kpiValue="model.finoperacionesdesatraque"
							:titleValueExceeded="$t('grid.stopperformance.finoperacionesdesatraque_desc_sup')"
							:titleValueNoExceeded="$t('grid.stopperformance.finoperacionesdesatraque_desc_no_sup')"
							:dailyMean="model.endcargoopunberthingdailymean"
							:weeklyMean="model.endcargoopunberthingweeklymean"
							:eventsList="endcargoopunberthingEventList"
							:portId="model.portid"
							:locationId="model.locationid"
						></stop-performance-form-card>
					</v-col>
					<v-col xs="12" sm="12" md="6" lg="3">
						<stop-performance-form-card
							:kpiId="'desatraquesalida'"
							:kpiLabel="$t('grid.stopperformance.desatraquesalida')"
							:kpiValue="model.desatraquesalida"
							:titleValueExceeded="$t('grid.stopperformance.desatraquesalida_desc_sup')"
							:titleValueNoExceeded="$t('grid.stopperformance.desatraquesalida_desc_no_sup')"
							:dailyMean="model.unberthingdeparturedailymean"
							:weeklyMean="model.unberthingdepartureweeklymean"
							:eventsList="unberthingdepartureEventList"
							:portId="model.portid"
							:locationId="model.locationid"
						></stop-performance-form-card>
					</v-col>
				</v-row>
			</pui-field-set>
			<br />
			<pui-field-set :title="$t('grid.stopperformance.terminal')" :id="'stopperformance-form-fieldset-terminal'">
				<v-row class="mt-1 mb-3 ml-1 mr-1">
					<v-col xs="12" sm="12" md="6" lg="3">
						<stop-performance-form-card
							:kpiId="'arrivalidletime'"
							:kpiLabel="$t('grid.stopperformance.arrivalidletime')"
							:kpiValue="model.arrivalidletime"
							:titleValueExceeded="$t('grid.stopperformance.arrival_idle_time_desc_sup')"
							:titleValueNoExceeded="$t('grid.stopperformance.arrival_idle_time_desc_no_sup')"
							:dailyMean="model.arrivalidledailymean"
							:weeklyMean="model.arrivalidleweeklymean"
							:eventsList="arrivalidleEventList"
							:portId="model.portid"
							:locationId="model.locationid"
						></stop-performance-form-card>
					</v-col>
					<v-col xs="12" sm="12" md="6" lg="3">
						<stop-performance-form-card
							:kpiId="'departureidletime'"
							:kpiLabel="$t('grid.stopperformance.departureidletime')"
							:kpiValue="model.departureidletime"
							:titleValueExceeded="$t('grid.stopperformance.departure_idle_time_desc_sup')"
							:titleValueNoExceeded="$t('grid.stopperformance.departure_idle_time_desc_no_sup')"
							:dailyMean="model.departureidledailymean"
							:weeklyMean="model.departureidleweeklymean"
							:eventsList="departureidleEventList"
							:portId="model.portid"
							:locationId="model.locationid"
						></stop-performance-form-card>
					</v-col>
					<v-col xs="12" sm="12" md="6" lg="3">
						<stop-performance-form-card
							:kpiId="'arrivalidletimeterminal'"
							:kpiLabel="$t('grid.stopperformance.arrivalidletimeterminal')"
							:kpiValue="model.arrivalidletimeterminal"
							:titleValueExceeded="$t('grid.stopperformance.arrival_idle_time_desc_sup')"
							:titleValueNoExceeded="$t('grid.stopperformance.arrival_idle_time_desc_no_sup')"
							:dailyMean="model.arrivalidleterminaldailymean"
							:weeklyMean="model.arrivalidleterminalweeklymean"
							:eventsList="arrivalidleterminalEventList"
							:portId="model.portid"
							:locationId="model.locationid"
						></stop-performance-form-card>
					</v-col>
					<v-col xs="12" sm="12" md="6" lg="3">
						<stop-performance-form-card
							:kpiId="'departureidletimeterminal'"
							:kpiLabel="$t('grid.stopperformance.departureidletimeterminal')"
							:kpiValue="model.departureidletimeterminal"
							:titleValueExceeded="$t('grid.stopperformance.departure_idle_time_desc_sup')"
							:titleValueNoExceeded="$t('grid.stopperformance.departure_idle_time_desc_no_sup')"
							:dailyMean="model.departureidleterminaldailymean"
							:weeklyMean="model.departureidleterminalweeklymean"
							:eventsList="departureidleterminalEventList"
							:portId="model.portid"
							:locationId="model.locationid"
						></stop-performance-form-card>
					</v-col>
				</v-row>
			</pui-field-set>
		</v-container>

		<pui-form-footer>
			<pui-form-footer-btns
				:formDisabled="true"
				:save="save"
				:back="back"
			></pui-form-footer-btns>
		</pui-form-footer>
	</div>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import StopPerformanceFormCard from './StopPerformanceFormCard.vue';

export default {
	mixins: [PuiFormMethodsMixin],
	components: { StopPerformanceFormCard },
	data() {
		return {
			modelName: 'stopperformance',
			maxHeight: window.innerHeight - 64 + 'px'
		};
	},
	computed: {
		arrivalberthingEventList() {
			return [
				{ label: this.$t('grid.stopperformance.ata'), value: this.model.atavisit },
				{ label: this.$t('grid.stopperformance.pba'), value: this.model.pba },
				{ label: this.$t('grid.stopperformance.fla'), value: this.model.fla }
			];
		},
		berthingfirstcargoEventList() {
			return [
				{ label: this.$t('grid.stopperformance.fla'), value: this.model.fla },
				{ label: this.$t('grid.stopperformance.afa'), value: this.model.afaterminal },
				{ label: this.$t('grid.stopperformance.fcl'), value: this.model.fcl }
			];
		},
		endcargoopunberthingEventList() {
			return [
				{ label: this.$t('grid.stopperformance.lcl'), value: this.model.lcl },
				{ label: this.$t('grid.stopperformance.rts'), value: this.model.rts },
				{ label: this.$t('grid.stopperformance.pbd'), value: this.model.pbd },
				{ label: this.$t('grid.stopperformance.lla'), value: this.model.atdstop }
			];
		},
		unberthingdepartureEventList() {
			return [
				{ label: this.$t('grid.stopperformance.lla'), value: this.model.atdstop },
				{ label: this.$t('grid.stopperformance.atd'), value: this.model.atdvisit }
			];
		},
		arrivalidleEventList() {
			return [
				{ label: this.$t('grid.stopperformance.fsm'), value: this.model.fsm },
				{ label: this.$t('grid.stopperformance.afa'), value: this.model.afaterminal }
			];
		},
		departureidleEventList() {
			return [
				{ label: this.$t('grid.stopperformance.lsm'), value: this.model.lsm },
				{ label: this.$t('grid.stopperformance.lla'), value: this.model.llaterminal }
			];
		},
		arrivalidleterminalEventList() {
			return [
				{ label: this.$t('grid.stopperformance.fcl'), value: this.model.fcl },
				{ label: this.$t('grid.stopperformance.fsm'), value: this.model.fsm }
			];
		},
		departureidleterminalEventList() {
			return [
				{ label: this.$t('grid.stopperformance.lcl'), value: this.model.lcl },
				{ label: this.$t('grid.stopperformance.lsm'), value: this.model.lsm }
			];
		}
	},
	beforeMount() {
		// hide pui9 header
		setTimeout(() => {
			document.getElementsByClassName('v-main__wrap')[0].firstElementChild.style.display = 'none';
		}, 10);
	},
	beforeDestroy() {
		// show pui9 header
		document.getElementsByClassName('v-main__wrap')[0].firstElementChild.style.display = 'block';
	}
};
</script>
