export default {
	methods: {
		exceedTargetIdleTime(datetime, prop, portid, locationid) {
			const ddhhmmss = datetime.split(' ');
			const hhmmss = ddhhmmss[1].split(':');
			const hh = parseInt(hhmmss[0]);
			const mm = parseInt(hhmmss[1]);
			const ss = parseInt(hhmmss[2]);
			const minutes = hh * 60 + mm;

			let targetIdleTime = this.getTargetIdleTime(prop, portid, locationid);

			if (targetIdleTime && (minutes > targetIdleTime || (minutes === targetIdleTime && ss > 0))) {
				return true;
			}
			return false;
		},
		getTargetIdleTime(prop, portid, locationid) {
			let targetIdleTime;
			if (prop === 'departureidletime' || prop === 'arrivalidletime' || prop === 'departureidletimeterminal' || prop === 'arrivalidletimeterminal') {
				targetIdleTime = this.$store.getters.getTargetIdleTimeBy(portid, locationid, prop);
			} else if (prop === 'llegadaatraque' || prop === 'desatraquesalida') {
				//targetIdleTime = 120;
				targetIdleTime = this.$store.getters.getTargetIdleTimeBy(portid, locationid, prop);
			} else if (prop === 'atraqueiniciooperaciones' || prop === 'finoperacionesdesatraque') {
				//targetIdleTime = 60;
				targetIdleTime = this.$store.getters.getTargetIdleTimeBy(portid, locationid, prop);
			}
			return targetIdleTime;
		}
	}
}
