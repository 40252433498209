<template>
	<v-chart class="chart" :option="option" />
</template>

<script>
import StopPerformanceMixin from '@/mixins/StopPerformanceMixin.js';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent, MarkLineComponent, GridComponent } from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent, MarkLineComponent, GridComponent]);

export default {
	components: {
		VChart
	},
	mixins: [StopPerformanceMixin],
	props: {
		kpiId: {
			type: String,
			required: true
		},
		dailyMean: {
			type: String | null,
			required: true
		},
		weeklyMean: {
			type: String | null,
			required: true
		},
		kpi: {
			type: String,
			required: true
		},
		portId: {
			type: Number | null,
			required: true
		},
		locationId: {
			type: Number | null,
			required: true
		}
	},
	provide: {
		[THEME_KEY]: 'white'
	},
	methods: {
		getKPIToDate(kpi) {
			let kpiFormatted = null;
			if (kpi) {
				let actualDate = new Date();
				let hours = parseInt(kpi.split(' ')[1].split(':')[0]) + '';
				let minutes = parseInt(kpi.split(' ')[1].split(':')[1]) + '';

				if (hours.length == 1) {
					hours = '0' + hours;
				}

				if (minutes.length == 1) {
					minutes = '0' + minutes;
				}

				let year = actualDate.getFullYear();
				let month = actualDate.getMonth() + 1 + '';
				let day = actualDate.getDate() + '';

				if (month.length == 1) {
					month = '0' + month;
				}

				if (day.length == 1) {
					day = '0' + day;
				}

				actualDate.setHours(hours, minutes, 0);
				kpiFormatted = `${year}-${month}-${day} ${hours}:${minutes}:00`;
			}

			return kpiFormatted;
		},
		isKPIExceeded(label, value) {
			if (value && this.exceedTargetIdleTime(value, label, this.portId, this.locationId)) {
				return true;
			}
			return false;
		},
		getMin() {
			let actualDate = new Date();

			let year = actualDate.getFullYear();
			let month = actualDate.getMonth() + 1 + '';
			let day = actualDate.getDate() + '';

			if (month.length == 1) {
				month = '0' + month;
			}

			if (day.length == 1) {
				day = '0' + day;
			}

			return `${year}-${month}-${day} 00:00:00`;
		}
	},
	data() {
		return {
			option: {
				xAxis: {
					type: 'time',
					show: false,
					min: this.getMin()
				},
				yAxis: {
					data: [this.$t('form.stopperformance.weeklycomparative'), this.$t('form.stopperformance.dailycomparative')]
				},
				tooltip: {
					trigger: 'item',
					formatter: function (obj) {
						let hours = parseInt(obj.value.split(' ')[1].split(':')[0]) + '';
						let minutes = parseInt(obj.value.split(' ')[1].split(':')[1]) + '';

						if (hours.length == 1) {
							hours = '0' + hours;
						}

						if (minutes.length == 1) {
							minutes = '0' + minutes;
						}

						return `${obj.name}: ${hours}:${minutes}`;
					}
				},
				grid: {
					left: '5%',
					right: '5%',
					bottom: '10%',
					top: '10%',
					containLabel: true
				},
				series: [
					{
						data: [
							{
								value: this.getKPIToDate(this.dailyMean),
								itemStyle: {
									color: this.isKPIExceeded(this.kpiId, this.dailyMean) ? 'red' : 'green'
								}
							},
							{
								value: this.getKPIToDate(this.weeklyMean),
								itemStyle: {
									color: this.isKPIExceeded(this.kpiId, this.weeklyMean) ? 'red' : 'green'
								}
							}
						],
						type: 'bar',
						barWidth: '50%',
						markLine: {
							data: [
								{
									name: 'KPI',
									type: 'time',
									xAxis: this.getKPIToDate(this.kpi),
									lineStyle: {
										width: 5,
										type: 'dotted'
									}
								}
							],
							symbol: 'none',
							silent: true,
							label: {
								formatter: function (obj) {
									let hours = parseInt(obj.value.split(' ')[1].split(':')[0]) + '';
									let minutes = parseInt(obj.value.split(' ')[1].split(':')[1]) + '';

									if (hours.length == 1) {
										hours = '0' + hours;
									}

									if (minutes.length == 1) {
										minutes = '0' + minutes;
									}

									return `${hours}:${minutes}`;
								}
							}
						}
					}
				]
			}
		};
	}
};
</script>

<style scoped>
.chart {
	height: 250px;
}
</style>
